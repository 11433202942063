import React from "react";
import { viewports, colors } from "../../style-vars";

const { lgOrSmaller, mdOrSmaller, smOrSmaller } = viewports;
const { white, primary } = colors;

const VideoSectionStyles = () => (
  <style jsx global>{`
    .video-section {
      position: relative;
      padding: 80px 0;
      margin: 24px 0;
      background-repeat: repeat-x;
      background-position: left -20% top !important;
    }
    @media ${mdOrSmaller} {
      .video-section {
        padding: 80px 32px;
        background-position: left 50% top !important;
      }
    }
    @media ${smOrSmaller} {
      .video-section {
        padding: 0 32px;
        margin-top: 0;
      }
    }
    .video-section h2 {
      font-size: 32px;
      font-weight: 800 !important;
    }
    .video-section__player {
      background-color: ${white};
      max-width: 550px;
      max-height: 350px;
      min-height: 350px;
      position: relative;
      border-radius: 28px;
      margin: 24px auto;
      z-index: 9;
      //overflow: hidden;
      box-shadow: 0 3.5px 4.5px -15px rgba(0, 0, 0, 0.028),
        0 9.6px 12.5px -15px rgba(0, 0, 0, 0.04),
        0 23.2px 30.1px -15px rgba(0, 0, 0, 0.052),
        0 77px 100px -15px rgba(0, 0, 0, 0.08);
    }
    .video-section__player.home .video-section__poster img {
      border-radius: 32px;
    }
    .video-section__player.home {
      position: absolute;
      top: 10%;
      min-width: 550px;
      margin: auto;
      margin-left: -20px;
      z-index: 99;
    }
    @media ${lgOrSmaller} {
      .video-section__player.home {
        min-width: 500px;
      }
    }
    @media ${mdOrSmaller} {
      .video-section__player.home {
        min-width: 70vw !important;
        left: 15vw;
        top: 20%;
        min-width: 550px;
        margin: auto;
      }
    }
    @media ${smOrSmaller} {
      .video-section__player.home {
        width: 95vw;
        top: 25%;
        left: 50%;
        transform: translate(-50%, 0%);
        margin: auto;
        height: 300px !important;
      }
    }
    @media ${smOrSmaller} {
      .video-section__player {
        min-height: 300px;
      }
    }
    .video-section__player button {
      background: ${primary};
      color: ${white};
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border: none;
      padding: 16px 40px;
      font-size: 20px;
      border-radius: 40px;
    }
    .video-section__player button span img {
      transform: translateX(4px) translateY(-2px);
    }
    .video-section__poster {
      position: initial !important;
      width: 100% !important;
      object-fit: scale-down !important;
      object-position: center;
      height: 350px;
    }
    @media ${smOrSmaller} {
      .video-section__poster {
        top: 55px;
        height: 100%;
      }
    }
    .video-section__poster.relative {
      position: relative !important;
    }
    .video-section__poster.bg-blue {
      background-color: #092a66;
    }
    .video-section__yt {
      max-width: 100%;
      height: 350px;
      border-radius: 28px;
    }
    @media ${smOrSmaller} {
      .video-section__yt {
        height: 300px;
      }
    }
  `}</style>
);

export default VideoSectionStyles;
