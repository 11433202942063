import React from "react";
import { string } from "prop-types";

const PowerPartnerBanner = ({ className }) => {
  return (
    <a
      className={`${className} cursor-pointer`}
      href="https://www.spoton.com/blog/spoton-named-inc-inaugural-power-partner-awards/"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="/power-partner.png"
        alt="power partner"
        className="max-w-[220px]"
      />
    </a>
  );
};

PowerPartnerBanner.propTypes = {
  className: string,
};

PowerPartnerBanner.defaultProps = {
  className: "",
};

export default PowerPartnerBanner;

const PowerPartnerBannerMobile = ({ className }) => {
  return (
    <a
      className={`${className} cursor-pointer z-1`}
      href="https://www.spoton.com/blog/spoton-named-inc-inaugural-power-partner-awards/"
      target="_blank"
      rel="noreferrer"
    >
      <div className="bg-black-800 h-10 w-full flex items-start">
        <img
          src="/power-partner.png"
          alt="power partner"
          className="w-[105px] h-[105px] mt-[-27px]"
        />
        <div className="text-p text-black mt-2">
          SpotOn Inc. Power Partner 2022
        </div>
      </div>
    </a>
  );
};

PowerPartnerBannerMobile.propTypes = {
  className: string,
};

PowerPartnerBannerMobile.defaultProps = {
  className: "",
};

export { PowerPartnerBannerMobile };
