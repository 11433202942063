import React from "react";
import { viewports, colors } from "../../style-vars";

const { xsOnly, smOrSmaller, mdOrSmaller, xlOrBigger } = viewports;
const { white, black } = colors;

const HeroStyles = ({ hideBanner }) => (
  <style jsx global>{`
    .local-hero {
      position: relative;
      width: 100%;
      overflow: hidden;
      min-height: 737px;
      padding: 0 126px;
      margin-top: 80px;
    }
    .local-title {
      font-weight: 800;
      font-size: 48px;
      line-height: 1.7;
      color: #090d12;
    }
    .video-placeholder {
      position: relative;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.13);
      background-color: transparent;
      border-radius: 8px;
      width: 100%;
      max-height: 330px;
    }
    .video-controls {
      position: absolute;
      top: 55%;
      left: 55%;
      transform: translate(-60%, -50%);
      text-align: center;
    }
    .hero {
      position: relative;
      min-height: 650px;
      padding-top: 100px;
    }
    .smaller-hero {
      width: 80%;
    }
    @media ${mdOrSmaller} {
      .hero {
        margin-top: 120px;
        min-height: auto;
        padding-top: 0px;
      }
      .smaller-hero {
        width: 100%;
      }
    }
    .hero__content {
      padding-left: 100px;
      padding-right: 80px;
      z-index: 8;
    }
    @media ${mdOrSmaller} {
      .hero__content {
        padding: 0 16px;
        margin-bottom: 40px;
      }
    }
    .hero__featured {
      min-width: 500px;
      max-height: 1000px;
    }
    @media ${mdOrSmaller} {
      .hero__featured {
        min-width: auto;
        max-width: 100vw;
        margin: auto;
        min-height: 370px;
      }
    }
    .hero__bg {
      position: absolute;
      right: 0;
      max-width: 83%;
      top: -8%;
    }
    @media ${mdOrSmaller} {
      .hero__bg {
        top: 10px;
        right: 0;
      }
    }
    @media ${smOrSmaller} {
      .hero {
        padding-top: ${hideBanner ? 0 : 100}px;
        margin-top: 0;
      }
      .hero-b {
        margin-top: 120px !important;
      }
      .hero__bg {
        top: 4%;
        max-width: 100%;
        right: 0;
      }
    }
    .hero__main {
      z-index: 7;
    }
    @media ${mdOrSmaller} {
      .hero__main {
        margin: 0 auto !important;
      }
    }
    .hero__main.fixed-width {
      max-width: 520px !important;
    }
    .hero__main.fixed-height {
      max-height: 560px !important;
    }
    .hero__main.fixed-height-600 {
      max-height: 600px !important;
      max-width: 600px !important;
    }

    .hero__main.fixed-height-700 {
      max-height: 700px !important;
      max-width: 1000px !important;
    }
    .hero__main.top-adjustement {
      margin-top: -60px !important;
    }
    .hero__main.top-adjustement-b {
      margin-top: -120px !important;
      width: 100%;
    }
    @media ${mdOrSmaller} {
      .hero__main.top-adjustement {
        margin-top: 0px !important;
      }
    }
    .hero__btns {
      justify-content: center;
    }
    @media ${mdOrSmaller} {
      .hero__btns {
        margin: 0 auto !important;
      }
    }
    @media ${smOrSmaller} {
      .hero__btns {
        justify-content: center;
      }
    }
    .hero__buttnos {
      justify-content: flex-start;
    }
    @media ${mdOrSmaller} {
      .hero__buttnos {
        margin: 0 auto !important;
      }
    }
    @media ${smOrSmaller} {
      .hero__buttnos {
        justify-content: center;
      }
    }
    .hero__fat-title {
      position: absolute;
      font-weight: 900;
      font-size: calc((75vw - 5rem) / 5);
      left: 50%;
      bottom: -5%;
      opacity: 0.05;
      z-index: 7;
      transform: translateY(50px) translateX(-50%);
    }
    @media ${mdOrSmaller} {
      .hero__fat-title {
        display: none;
      }
    }
    @media ${xlOrBigger} {
      .hero__fat-title {
        font-size: calc((75vw - 5rem) / 7);
      }
    }
    .hero__fat-title.font-large {
      font-size: calc((75vw - 5rem) / 2.5);
    }
    .hero__fat-title.full-width {
      font-size: calc((75vw - 5rem) / 5);
      bottom: 12%;
      z-index: 7;
      text-align: center;
      width: 100%;
    }
    .hero-b {
      padding: 150px 20%;
      max-width: 100%;
      margin: 40px 0;
      border-radius: 24px !important;
      overflow: hidden;
      background-position: top;
      position: relative;
    }
    #videoBG {
      position: absolute;
      left: 0;
      top: 0;
    }
    .special-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: none;
    }
    @media (max-aspect-ratio: 16/9) {
      #videoBG {
        width: 100%;
        height: auto;
      }
    }
    @media ${mdOrSmaller} {
      .hero-b {
        padding-top: 110px !important;
      }
      video {
        display: none !important;
      }
      .special-image {
        display: block;
      }
    }
    @media ${smOrSmaller} {
      .hero-b {
        padding-top: 40px !important;
        min-height: 400px !important;
        margin-top: 120px !important;
        padding: 0px;
        background-position: center;
      }
    }
    .super-white {
    }
    .hero-b h1.hero-b__title {
      text-align: center !important;
      white-space: pre-line;
      font-size: 42px !important;
    }
    .hero-b__para {
      font-size: 20px;
    }
    .hero-b .cta-secondary {
      color: ${white} !important;
    }
    .contact-us-hero {
      background-size: contain !important;
      background-repeat: no-repeat !important;
      padding: 105px 32px;
      margin: auto;
      max-width: 100%;
      min-height: 560px;
    }
    @media ${mdOrSmaller} {
      .contact-us-hero {
        padding-top: 110px !important;
      }
    }
    @media ${smOrSmaller} {
      .contact-us-hero {
        padding-top: 80px !important;
      }
      .hero-b__para {
        font-size: 16px;
      }
    }
    .contact-us-hero h1 {
      color: ${black} !important;
    }
    .contact-us-hero p {
      color: ${black} !important;
      font-size: 20px;
    }
    .contact-us-hero .cta-secondary {
      color: ${white};
    }
    .guy-logo {
      position: absolute !important;
      width: 200px !important;
      bottom: 0%;
      left: -17%;
      z-index: 8;
    }
    @media ${mdOrSmaller} {
      .guy-logo {
        left: 0 !important;
      }
    }
    @media ${smOrSmaller} {
      .guy-logo {
        width: 150px !important;
        left: 55% !important;
        bottom: 18% !important;
      }
      #careers-bg .hero-b:before {
        background-position: top !important;
        background-size: contain !important;
      }
      #careers-bg .hero-b:after {
        background-position: top !important;
        background-size: contain !important;
      }
    }
    @media ${xsOnly} {
      .guy-logo {
        bottom: 0 !important;
      }
    }
  `}</style>
);

export default HeroStyles;
