import React from "react";
import { string, element, bool } from "prop-types";
import Layout from "./layout";

const LpLayout = (props) => {
  const { phoneNumber, phoneText, children, getADemo } = props;
  return (
    <Layout
      isLanding
      getADemo={getADemo}
      phoneNumber={phoneNumber}
      phoneText={phoneText}
    >
      {children}
    </Layout>
  );
};

LpLayout.propTypes = {
  phoneNumber: string,
  phoneText: string,
  children: element.isRequired,
  getADemo: bool,
};

LpLayout.defaultProps = {
  phoneNumber: "",
  phoneText: "",
  getADemo: false,
};

export default LpLayout;
