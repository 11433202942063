import React, { useState } from "react";
import PropTypes from "prop-types";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Row, Col, Typography } from "antd";
import YouTube from "react-youtube";
import PrimaryCta from "../Buttons/primary-cta";
import SecondaryCta from "../Buttons/secondary-cta";
import Image from "../ImageQuerys/HeroImages";
import VideoThumbnail from "../ImageQuerys/PosterImages";
import circles from "../../images/svg/hero_bg.svg";
import check from "../../images/svg/checkmark.svg";
import HeroStyles from "./HeroStyles";
import VideoSectionStyles from "../VideoSection/VideoSectionStyles";
import PowerPartnerBanner, {
  PowerPartnerBannerMobile,
} from "../../components-v2/PowerPartnerBanner";

const { Title, Paragraph } = Typography;

const Hero = (props) => {
  const {
    sectionData,
    circleBg,
    guy,
    dynamicH1,
    hideBanner,
    withBullets,
    style,
    className,
    paragraphClassName,
    showPowerPartnerBanner,
    titleClassName,
  } = props;
  const [playing, setPlaying] = useState(false);

  const startPlaying = () => {
    setPlaying(true);
  };
  const endPlaying = () => {
    setPlaying(false);
  };
  const onPlayerReady = (event) => {
    event.target.playVideo();
  };
  return (
    <section className={`hero z-0 ${className}`} style={style}>
      <Row>
        <Col lg={12} xs={24} className="hero__content">
          <Title className={`main-heading ${titleClassName}`}>
            {dynamicH1 || sectionData.title}
            <span className="blue_dot">.</span>
          </Title>
          <Paragraph className={`normal-paragraph ${paragraphClassName}`}>
            {withBullets.length === 0 && sectionData.subtext}
          </Paragraph>
          {dynamicH1 && withBullets && (
            <ul style={{ listStyle: "none" }}>
              {withBullets.map((bulletPoint) => {
                return (
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "18px 0",
                    }}
                  >
                    <img src={check} alt="" />
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 800,
                        marginBottom: 0,
                        marginLeft: 10,
                        color: "#445469",
                      }}
                    >
                      {bulletPoint}
                    </p>
                  </li>
                );
              })}
            </ul>
          )}
          <Row
            style={{ marginTop: "42px" }}
            className={`hero__buttons z-10 `}
            align="middle"
            wrap={false}
          >
            {sectionData.PrimaryCta && (
              <PrimaryCta
                ctaTitle={sectionData.PrimaryCta.ctaTitle}
                target={sectionData.PrimaryCta.ctaTarget}
              />
            )}
            {sectionData.SecondaryCta && (
              <SecondaryCta
                ctaTitle={sectionData.SecondaryCta.ctaTitle}
                target={sectionData.SecondaryCta.ctaTarget}
              />
            )}
            {sectionData.AnchorCta && (
              <AnchorLink to={sectionData?.AnchorCta?.linkTo ?? ""} stripHash>
                <PrimaryCta
                  ctaTitle={sectionData.AnchorCta.ctaTitle}
                  onClick={() => null}
                  // target={sectionData.PrimaryCta.ctaTarget}
                />
              </AnchorLink>
            )}
          </Row>
        </Col>
        <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24}>
          <div className="hero__featured">
            {circleBg && (
              <img
                src={circles}
                className="hero__bg"
                alt="background circles"
                loading="lazy"
              />
            )}
            {sectionData.heroImg && (
              <Image
                imageName={sectionData.heroImg}
                className={`hero__main ${sectionData.imageClassName} ${
                  sectionData?.desktopImageClassName
                } ${sectionData.heroMobileImg ? "hidden lg:block" : ""}`}
                alt=""
              />
            )}
            {sectionData.heroMobileImg && (
              <Image
                imageName={sectionData.heroMobileImg}
                className={`hero__main ${sectionData.mobileImageClassName} ${
                  sectionData.heroMobileImg && " block lg:hidden"
                }`}
                alt=""
              />
            )}
            {guy && !playing && (
              <Image
                imageName="reboot-logo.png"
                className="guy-logo"
                // style={{ bottom: '20%', right: '0' }}
              />
            )}
            {sectionData.video && (
              <div
                className={`video-section__player home ${sectionData.video?.posterClass} relative`}
              >
                {showPowerPartnerBanner && (
                  <PowerPartnerBanner className="hidden md:block absolute top-[-100px] left-[-100px] z-1" />
                )}
                {!playing ? (
                  <>
                    <VideoThumbnail
                      imageName={sectionData.video.posterImg}
                      className={`video-section__poster ${sectionData.video.posterWrapperClass}`}
                    />
                    <PrimaryCta
                      className="play-video"
                      isVideo
                      ctaTitle={sectionData.video.videoBtn}
                      onClick={startPlaying}
                    />
                  </>
                ) : (
                  <YouTube
                    videoId={sectionData.video.videoUrlId}
                    onEnd={endPlaying}
                    onReady={onPlayerReady}
                    className="video-section__yt"
                    opts={{
                      playerVars: {
                        autoplay: 1,
                      },
                      height: "100%",
                      width: "100%",
                    }}
                  />
                )}
              </div>
            )}

            {showPowerPartnerBanner && (
              <PowerPartnerBannerMobile className="z-1 md:hidden absolute bottom-[-120px] left-0 right-0" />
            )}
          </div>
        </Col>
      </Row>
      {sectionData?.fatTitle && (
        <span className={`hero__fat-title ${sectionData.fatTitleClass || ""}`}>
          {sectionData.fatTitle}
        </span>
      )}

      <HeroStyles hideBanner={hideBanner} />
      <VideoSectionStyles />
    </section>
  );
};

Hero.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  circleBg: PropTypes.bool,
  guy: PropTypes.bool,
  dynamicH1: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  hideBanner: PropTypes.bool,
  withBullets: PropTypes.arrayOf(PropTypes.string),
  showPowerPartnerBanner: PropTypes.bool,
  paragraphClassName: PropTypes.string,
  titleClassName: PropTypes.string,
};
Hero.defaultProps = {
  sectionData: "",
  circleBg: false,
  guy: false,
  style: {},
  dynamicH1: "",
  hideBanner: false,
  withBullets: [],
  showPowerPartnerBanner: false,
  paragraphClassName: "",
  titleClassName: "",
};

export default Hero;
